.home-main {
  margin-top: 7rem;
  margin-bottom: 3rem;

  .categories {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    grid-auto-rows: auto;
    align-items: stretch;
    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  a.cat {
    display: inline;
    width: 100%;
    height: 100%;
    text-decoration: none;

    .cat__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      min-height: 200px;
      background-color: $orange;
      text-align: center;
      margin: 0;
      padding: 2.5em .625em 2.125em;
      border-radius: 3px;
      transition: 0.3s ease;
      &:hover,
      &:active,
      &:focus {
        cursor: pointer;
        background-color: $orange-hover;
      }

      img {
        margin-bottom: 1.2em;
        height: 4.125em;
      }

      h2 {
        text-transform: capitalize;
        font-size: 1.375em;
        font-weight: normal;
        letter-spacing: 1px;
        margin: 0;
        color: white;
      }

      p {
        font-weight: 300;
        margin: 0.5rem 0;
        line-height: 1.625;
        letter-spacing: .5px;
      }

      .cat__link {
        margin-top: auto;
        color: white;
        text-decoration: none;
        line-height: 3;
      }
    }
  }
}
