// With help from:
// https://google-webfonts-helper.herokuapp.com/fonts

@font-face {
  font-family: 'TheSansB';
  src: url('../fonts/TheSansB-W5Plain.eot');
  src: url('../fonts/TheSansB-W5Plain.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TheSansB-W5Plain.woff2') format('woff2'),
  url('../fonts/TheSansB-W5Plain.woff') format('woff'),
  url('../fonts/TheSansB-W5Plain.ttf') format('truetype'),
  url('../fonts/TheSansB-W5Plain.svg#TheSansB-W5Plain') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TheSansB';
  src: url('../fonts/TheSansB-W7Bold.eot');
  src: url('../fonts/TheSansB-W7Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TheSansB-W7Bold.woff2') format('woff2'),
  url('../fonts/TheSansB-W7Bold.woff') format('woff'),
  url('../fonts/TheSansB-W7Bold.ttf') format('truetype'),
  url('../fonts/TheSansB-W7Bold.svg#TheSansB-W7Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Source Serif Pro';
  src: url('../fonts/SourceSerifPro-Regular.eot');
  src: url('../fonts/SourceSerifPro-Regular.woff2') format('woff2'),
  url('../fonts/SourceSerifPro-Regular.woff') format('woff'),
  url('../fonts/SourceSerifPro-Regular.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
