#cookies-information {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10000;
  background: $main-color;
  color: white;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  border-bottom: hidden;
  padding: 20px;
  font-size: 16px;
  display: none;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .text {
    .desktop {
      @media (max-width: 767px) {
        display: none;
      }
    }
    .mobile {
      text-align: center;
      @media (min-width: 768px) {
        display: none;
      }
    }
    p {
      margin: 1em;
      font-weight: 300;
      color: white;
      @media (max-width: 767px) {
        margin: 0;
      }
    }
    h3 {
      margin: 1em;
      color: white;
      @media (max-width: 767px) {
        margin: 0;
      }
    }
  }
  #cookies-information-closer {
    background: transparent;
    padding: 10px 25px;
    border: 1px solid white;
    min-width: 150px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    @media (max-width: 767px) {
      padding: 4px;
    }
    &:hover {
      background: darken($main-color, 5%);
    }
    &:active {
      background: darken($main-color, 15%);
    }
    @media (max-width: 767px) {
      margin-top: 10px;
    }
  }
}
