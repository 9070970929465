/* If fixed Header */

.main {
  margin-top: 38px;
}

.luxbar-navigation {
  max-width: 1228px;
  margin: 0 2rem;
  text-transform: uppercase;
  @media (min-width: 768px) {
    margin: 0 auto;
  }

  .luxbar-header {
    @media (min-width: 768px) {
      height: 64px;
      margin-left: 1rem;
      order: 8;
    }
  }
}
