.search {
  display: flex;
  position: relative;
  height: 36px;
  margin: auto 0;
  text-align: center;

  input[type=image] {
    display: inline-block;
    height: 36px;
    width: auto;
    padding: 7px 12px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border: none;
    background-color: $breadcrumb-color;
    color: #FFFFFF;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: $orange;
    }

  }
  ::placeholder {
    color: #4e5665;
    opacity: 0.5;
  }

  input[type=search] {
    color: #4e5665;
    width: 85%;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    padding: 6px 8px 4px 18px;
    border: none;
    font-size: 15px;
    line-height: 24px;
    max-width: 600px;
    background-color: $breadcrumb-color;
    &:focus {
      outline: none;
    }
  }


}

ul {
  hr {
    border: 0.5px solid #d8e1e7;
    opacity: 0.5;
  }
  margin-right: 30px;
  .result {
    list-style: none;

    a {
      text-decoration: none;
      font-size: 20px;
      color: $main-color;
      font-weight: bold;

      &:hover {
        color: $orange;
      }

    }
  }
}

.search-bar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
}

.search-feature {
  .search {
    display: block;
    text-align: left;
    margin: 20px 0 40px 0;
  }
}
