.article-header {
  .subheading {
    font-size: 2rem;
  }

  h1:first-of-type {
    margin-top: 0;
  }

  .edit-icon {
    display: inline;
    padding: 10px;
  }

  .last-update {
    font-size: 1.2rem;
    opacity: 0.6;
  }

  .subheading-box {
    background-color: $breadcrumb-color;
    padding: 15px 30px;
    margin-top: 2rem;
    @media (min-width: 768px) {
      margin-top: 3rem;
    }
    .box-heading {
      font-weight: bold;
    }

    a {
      display: inline;
    }

    .section-id {
      color: $light-grey;
      margin-right: 8px;
    }
  }
  .divider {
    margin: 5rem 0 1.4rem;
    border-bottom: 1px solid #d8e1e7;
  }

  figure {
    img {
      max-width: 100%;
    }
  }

  /* Code Block */
  code {
    display: block;
    padding: 16px;
    background-color: #e7eef2;
    color: $grey;
  }

  /* Hinweis Block */
  blockquote {
    display: block;
    position: relative;
    margin: 24px 0;
    padding: 16px 24px 16px 72px;
    background-color: #e7eef2;
    color: $grey;

    p {
      margin: 0;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 24px;
      height: 100%;
      top: 0;
      left: 24px;
      background-image: url('data:image/svg+xml;utf8,<svg enable-background="new 0 0 22 22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m10 13h2v-6h-2zm1 3c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm0-16-11 19h22z" fill="#636362" fill-rule="evenodd"/></svg>');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
  }
}
