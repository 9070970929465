* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;
  color: #636362;
}

footer {
  margin-top: auto;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  @media (min-width: 768px) {
    padding: 0 75px;
  }
}

@media (max-width: 767px) {
  .col-md-9, .col-md-3, .col {
    padding: 0;
  }
}
