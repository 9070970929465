/* stylelint-disable */

#CybotCookiebotDialog {
  background-color: #707173;
  color: white;
}

#CybotCookiebotDialog a {
  color: white !important;
}

#CybotCookiebotDialogPoweredbyImage {
  display: none;
}

#CybotCookiebotDialogHeader,
#CybotCookiebotDialogNav,
#CybotCookiebotDialog .CybotCookiebotScrollContainer,
#CybotCookiebotDialog .CookieCard,
#CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonWrapper:not(:last-of-type),
#CybotCookiebotDialogBodyLevelButtonsSelectPane {
  border-color: #858585;
}

#CybotCookiebotDialogTabContent input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: #ff6105;
}

.CybotCookiebotFader {
  background-image: linear-gradient(
    to top,
    rgb(112, 113, 115),
    rgba(112, 113, 115, 0.8),
    rgba(112, 113, 115, 0)
  ) !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink {
  border-bottom: 2px solid transparent !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-color: #ff6105 !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  border-color: #fff;
  background-color: #fff;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background-color: #ff6105;
  border-color: #ff6105;
}

#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a:after {
  color: #fff;
}

#CybotCookiebotDialogPoweredbyCybot svg {
  fill: #fff;
}

#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
  background-color: #ff6105 !important;
  border-color: #ff6105 !important;
}

#CookiebotWidget .CookiebotWidget-consent-details button {
  color: #ff6105 !important;
}

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
  fill: #ff6105 !important;
} /* stylelint-disable */

#CybotCookiebotDialog {
  background-color: #707173;
  color: white;
}

#CybotCookiebotDialog a {
  color: white !important;
}

#CybotCookiebotDialogPoweredbyImage {
  display: none;
}

#CybotCookiebotDialogHeader,
#CybotCookiebotDialogNav,
#CybotCookiebotDialog .CybotCookiebotScrollContainer,
#CybotCookiebotDialog .CookieCard,
#CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonWrapper:not(:last-of-type),
#CybotCookiebotDialogBodyLevelButtonsSelectPane {
  border-color: #858585;
}

#CybotCookiebotDialogTabContent input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: #ff6105;
}

.CybotCookiebotFader {
  background-image: linear-gradient(
    to top,
    rgb(112, 113, 115),
    rgba(112, 113, 115, 0.8),
    rgba(112, 113, 115, 0)
  ) !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink {
  border-bottom: 2px solid transparent !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-color: #ff6105 !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  border-color: #fff;
  background-color: #fff;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background-color: #ff6105;
  border-color: #ff6105;
}

#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a:after {
  color: #fff;
}

#CybotCookiebotDialogPoweredbyCybot svg {
  fill: #fff;
}

#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
  background-color: #ff6105 !important;
  border-color: #ff6105 !important;
}

#CookiebotWidget .CookiebotWidget-consent-details button {
  color: #ff6105 !important;
}

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
  fill: #ff6105 !important;
}
