.forgot-password-main {
  background-size: cover;
  .modal-content {
    padding: 1.5em;
    background: #fff;
    overflow: auto;
    z-index: 2;
    border-radius: 3px;
    margin: 6em 1.5em 3em;
    box-shadow: rgba(0, 0, 0, .2) 0 2px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .modal-head {
    text-align: center;
  }

  form {
    font-weight: 400;
    text-align: center;
  }

  h1 {
    margin: 0.75em auto 0.5em;
    font-weight: 700;
    font-size: 32px;
  }

  .input {
    padding: 6px;
    width: 100%;
    height: 40px;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;
    border: 2px solid #ddd;
    background: #fff;
    display: block;
    border-radius: 0;
    transition: border-color 0.3s ease-in-out;
  }

  .input:focus {
    outline: 0;
    border-color: #FF550C;
  }

  @media screen and (min-width: 30em) {
    .modal-content {
      width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  label {
    display: block;
    margin-top: 10px;
  }

  .login-page .alert {
    color: $orange;
    padding: 1.5em 1em 0.5em;
    text-align: center;
  }

  .modal-footer {
    text-align: center;
  }

  .button {
    border-radius: 30px;
    font-size: 16px;
    cursor: pointer;
    color: #FF550C;
    text-transform: uppercase;
    padding: .6em 1.6em;
    background: transparent;
    margin: 1em auto;
    border: 1px solid #FF550C;
    transition: background-color 0.3s ease-in-out;
  }

  .button:hover,
  .button:active,
  .button:focus {
    background: #FF550C;
    color: #fff;
  }
}
