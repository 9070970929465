//@import "compass/css3";

/* ==========================================================================
   Snipr Helper Classes
   ========================================================================== */


/* ==========================================================================
   01. Font Styles
   ========================================================================== */

.txt-bold {
    font-weight: bold;
}

.txt-left {
    text-align-last: left;
}

.txt-right {
    text-align-last: right;
}

.txt-justify {
    text-align-last: justify;
}

.txt-center {
    text-align-last: center;
}


/* ==========================================================================
   02. Margins - Paddings
   ========================================================================== */

.mb24 {
    margin-bottom: 24px;
}


/* ==========================================================================
   03. Element Styles
   ========================================================================== */

.ico-circle {
    border-radius: 100%;
    border: 1px solid #C4C9CE;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    img {
        display: inline-block;
    }
    &.small {
        width: 80px;
        height: 80px;
        padding: 20px;
        margin-bottom: 30px;
        margin-top: 30px;
    }
}

.border-between > [class*='col-']:before {
    background: #E1E6EC;
    bottom: 0;
    content: " ";
    left: 0;
    position: absolute;
    width: 1px;
    top: 0;
}

.ico-between > [class*='col-']:first-child:before {
    display: none;
}

.ico-between > [class*='col-']:before {
    background-image:url(../images/ico-chevron-right.svg);
    background-position: center;
    background-repeat: no-repeat;
    bottom: 0;
    content: " ";
    left: -3px;
    position: absolute;
    width: 12px;
    top: 0;
}

.border-between > [class*='col-']:first-child:before {
    display: none;
}

/* ==========================================================================
   04. Responsive
   ========================================================================== */
    /* Large Devices, Wide Screens */
    @media only screen and (max-width : 1200px) {
        .hidden-lg,
        .hidden-xs-lg {
            display: none;
        }
    }
    @media only screen and (min-width : 992px) {
        .show-xs-md {
            display: none;
        }
    }

    /* Medium Devices, Desktops */
    @media only screen and (max-width : 992px) {
        .hidden-md,
        .hidden-xs-md {
            display: none;
        }
    }

    /* Small Devices, Tablets */
    @media only screen and (max-width : 768px) {
        .hidden-sm,
        .hidden-xs-sm {
            display: none;
        }
    }

    /* Extra Small Devices, Phones */ 
    @media only screen and (max-width : 480px) {
        .hidden-xs {
            display: none;
        }
    }
