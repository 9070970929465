body {
  font-family: 'TheSansB', sans-serif;
  color: $text-color;
  font-size: 16px;
}

p, a {
  word-break: break-word;
}

a {
  color: $blue;
  display: inline-block;
  text-decoration: none;
  &:hover, &.focus {
    text-decoration: underline;
  }
}

h1 {
  font-family: 'Source Serif Pro', Arial, serif;
  color: $light-grey;
  font-weight: bold;
  font-size: 3.2rem;
  margin: .9em 0 .55em;
  @media (min-width: 768px) {
    font-size: 4.2rem;
  }
}

h2 {
  color: $light-grey;
  font-size: 2rem;
  margin: 1em 0 .65em;
  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
}

h3 {
  margin: 1em 0 .65em;
}
