.article-content {
  float: right;
  width: 75%;
  @media (max-width: 767px) {
    width: 100%;
  }

  .article {
    padding-bottom: 40px;

    .article-section {
      position: relative;
    }

    .divider {
      margin: 50px 0 40px;
      border-bottom: 1px solid #d8e1e7;
    }

    .section-img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }

    .anhaenge:hover {
      text-decoration: none;
    }

    a {
      display: inline;
    }
    .content-video {
      width: 100%;
      max-width: 400px;
      height: auto;
    }

    .sec-number {
      margin: 0 0.8rem 0 0;
      font-size: 1.8rem;
      color: #bbb;
    }

    .sec-id {
      margin-left: 10px;
      font-size: 1rem;
      color: #bbb
    }

    /* iFrame Videos im Text */
    .video {
      width: 100%;
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      background-color: black;
      margin: 1.5rem 0;
      iframe {
        width: 100%;
        height: 100%;
        border: none;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    /* Bilder im Text */
    figure img {
      max-width: 100%;
      margin: 1.5rem 0;
    }

    /* Tabellen */
    table {
      width: 100%;
      word-break: break-word;

      th {
        background-color: #eaeaea;
      }

      &, th, td {
        border: 2px solid #d0d0d0;
      }

      th, td {
        padding: 10px;
      }
    }

    /* Code Block */
    code {
      display: block;
      padding: 16px;
      background-color: #e7eef2;
      color: $grey;
    }

    /* Hinweis Block */
    blockquote {
      display: block;
      position: relative;
      margin: 24px 0;
      padding: 16px 24px 16px 72px;
      background-color: #e7eef2;
      color: $grey;
      @media (max-width: 767px) {
        padding: 16px 24px 16px 56px;
      }

      p {
        margin: 0;
      }
    }

    /* Image caption */
    .section-img-container {
      display: block;
      position: relative;
      margin: 2rem auto;
      max-width: 100%;
      @media (min-width: 768px) {
        margin: 2rem 0;
      }

      .image-caption {
        display: block;
        width: 100%;
        padding: 10px;
        margin-top: 0;
        background-color: #e7eef2;
      }
    }

    /* Image fullscreen button */

    .fullscreen-img-container {
      position: absolute;
      top: 10px;
      right: 10px;
      border: 2px solid $orange;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.65;
      }
    }


    /* Image-Text Section */

    .section-text-image {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;

      @media(max-width: 630px) {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
      }

    }

    /* Lupen hover */
    .lupen-section-container {
      position: relative;

      .lupen-container {
        position: absolute;
        width: 100%;
        height: 100%;

        .lupe {
          position: absolute;
          width: 14px;
          height: 14px;
          border-radius: 100%;
          background: white;
          cursor: pointer;

          .lupen-icon {
            position: absolute;
            top: -1px;
            left: -1px;
            width: 19px;
            pointer-events: none;
          }

          .lupe-anmerkung {
            display: none;
            position: absolute;
            width: 224px;
            left: 50%;
            top: 16px;
            transform: translateX(-50%);
            background: #fff;
            border: 2px solid $orange;
            padding: 8px 8px 6px 8px;
            font-size: 14px;
            line-height: 19px;
            color: $orange;
            pointer-events: none;

            .lupe-anmerkung-arrow {
              position: absolute;
              left: 50%;
              top: -3px;
              margin-left: -2px;
              width: 15px;
              height: 15px;
              transform: rotateZ(45deg) translateX(-50%);
              background: white;
              border-top: 2px solid $orange;
              border-left: 2px solid $orange;
            }
          }

          &:hover {
            .lupe-anmerkung {
              display: block;
            }
          }
        }
      }
    }
  }
}

/* Zitat Block
Ersetzt durch Hinweis block

blockquote {
display: block;
position: relative;
margin: 24px 10px;
padding: 16px 32px;
background-color: #e7eef2;

p {
margin: 0;
}

&:before, &:after {
position: absolute;
font-size: 54px;
line-height: 54px;
}

&:before {
content: '“';
top: -6px;
left: -11px;
}

&:after {
content: '”';
right: -11px;
bottom: -47px;
}
}
*/
