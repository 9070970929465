.subsub-nav {
  margin-top: 1rem;
  margin-bottom: 6rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    margin-bottom: 100px;
    height: 50vh;
    padding-left: 0.875rem;
    padding-right: 3.5rem;
  }

  .nav_topic {
    position: relative;
    padding: 10px 20px 6px 0;
    color: $main-color;
    text-decoration: none;
    font-size: 1.7rem;
    font-weight: 300;

    &:first-of-type {
      padding-top: 0;
    }
  }

  .nav-item {
    position: relative;
    color: $main-color;
  }
  .chevron {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    &.active {
      transform: rotate(90deg) translateX(-60%);
    }
  }

  ul.subnav {
    margin-top: 2px;
    list-style-type: none;
    padding-left: 0.5rem;

    li {
      color: $grey;
      padding: 0.2rem 0;

      a {
        padding: 0.525em;
        color: $grey;
        cursor: pointer;
      }
    }
  }

  /* hover states */
  a:hover {
    opacity: 0.6;
    text-decoration: underline;
  }

  .nav_topic.subactive:hover, .nav_topic.active:hover, ul.subnav li.active a:hover {
    color: $orange;
    opacity: 1;
    text-decoration: none;
  }

  /* active states */
  .nav_topic.subactive, .nav-item.active, ul.subnav li.active a {
    color: $orange;
    font-weight: bold;
  }
}
