.breadcrumbs {
  margin-top: 66px;
  font-size: 1.5rem;
  background-color: transparent !important;

  .container {
    width: 100vm;
    height: 0px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    @media (min-width: 768px) {
      border-bottom: 1px solid $orange;
      height: 60px;
    }
  }

  .topnav {
    li a {
      .icon {
        margin-left: 2rem;
        margin-right: -1rem;
      }

      &.active {
        position: relative;
        color: $orange;
        font-weight: bold;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          background-color: $orange;
          left: 50%;
          transform: translateX(-50%);
        }

        .icon {
          display: none;
        }
      }

      &:hover {
        opacity: .8;
      }
    }
  }
}
