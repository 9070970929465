.footer {
  border-top: 1px solid lightgray;

  img {
    padding: 5px 0;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
