$themes: (
dark: (bg: #212121, h1: #424242, fg: #fff),
light: (bg: #ffffff, h1: #bdbdbd, fg: #212121),
material-red: (bg: #b71c1c, h1: #c62828, fg: #fff),
material-cyan: (bg: #006064, h1: #00838f, fg: #fff),
material-white: (bg: #ffffff, h1: #424242, fg: #384050),
material-indigo: (bg: #1a237e, h1: #283593, fg: #fff),
material-green: (bg: #1b5e20, h1: #2e7d32, fg: #fff),
material-amber: (bg: #ff6f00, h1: #ff8f00, fg: #fff),
material-bluegrey: (bg: #263238, h1: #37474f, fg: #fff),
material-brown: (bg: #3e2723, h1: #4e342e, fg: #fff),
);

@each $theme in $themes {
  $key: nth($theme, 1);
  $value: nth($theme, 2);

  .luxbar-menu-#{$key},
  .luxbar-menu-#{$key} .dropdown ul {
    background-color: map-get(map-get($themes, $key), bg);
    color: map-get(map-get($themes, $key), fg);
  }

  .luxbar-menu-#{theme} {
    .active,
    .luxbar-item:hover {
      background-color: map-get(map-get($themes, $key), h1);
    }

    .luxbar-hamburger {
      span,
      span::before,
      span::after {
        background-color: map-get(map-get($themes, $key), fg);
      }
    }
  }
}

#luxbar {
  box-shadow: none;
  border-bottom: 2px solid #d8e1e7;

  .luxbar-menu {
    .luxbar-navigation {
      li.luxbar-item:hover {
        a {
          color: #636362;
          opacity: 0.6;
        }
      }
    }
  }
}
