#luxbar {
  .luxbar-navigation {
    @media (min-width: 768px) {
      margin: 0 20px !important;
    }
    @media (min-width: 1250px) {
      margin: 0 auto !important;
    }
  }

  .luxbar-menu {
    @media (min-width: 768px) {
      height: 64px;
    }
    .luxbar-item {
      @media (min-width: 768px) {
        height: 64px;
      }
    }
  }

  .luxbar-menu-left {
    .luxbar-hamburger {
      margin-left: auto;
    }
  }

  .menu-avatar {
    display: none;
    margin: 0 1rem;
    @media (min-width: 768px) {
      display: inline;
    }

    .avatar-img-container {
      margin: 15px 0;
      overflow: hidden;
      border-radius: 999px;
      width: 35px;
      height: 35px;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .menu-divider {
    display: none;
    @media (min-width: 768px) {
      display: inline;
      margin-right: 24px;
    }
  }

  .luxbar-item {
    color: $main-color !important;
    text-transform: none;

    a {
      padding: 21px 16px;
    }
  }

  .luxbar-item:hover {
    color: $main-color !important;
    background: none !important;
  }

  .searchbar {
    display: flex;
    align-items: center;
    max-width: 100%;
    @media (min-width: 768px) {
      margin-left: auto;
      margin-right: 1rem;
      max-width: 250px;
    }

    .search {
      width: 100%;
      margin-top: 2rem;
      @media (min-width: 768px) {
        margin-top: auto;
      }
    }
  }

  .dropdown {
    position: relative;
    padding: 21px 16px;
    height: 66px;
    text-transform: none;

    .flag-menu {
      width: 20px;
      margin-right: 3px;
    }

    .language-choice {
      display: flex;
      flex-direction: row;
      img {
        min-width: 20px;
        max-width: 20px;
        height: auto;
        align-self: center;
        margin-right: 5px;
      }
      &.active {
        opacity: 0.5;
        background: none;
      }
    }

    &-selection {
      position: relative;
      display: none;
      padding: 8px 0 0 0;
      white-space: nowrap;
      @media (min-width: 768px) {
        position: absolute;
        right: 16px;
        top: 44px;
        padding: 16px 16px 16px 13px;
        background: #fff;
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
      }

      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        li {
          margin: 0;
          padding: 8px 0;
          text-indent: 0;
          @media (min-width: 768px) {
            padding: 0;
          }
          &::before {
            content: ' ';
          }
        }
      }

      a, span {
        font-size: 16px;
      }

      .show {
        display: block
      }
    }

    &-selected {
      cursor: pointer;
    }

  }
}
