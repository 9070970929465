.category-main {
  margin-bottom: 3rem;

  .categories {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    align-items: stretch;
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  .category {
    position: relative;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    &.too-tall {
      height: 300px;
    }

    .cat__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 0;
      width: 100%;
      min-height: 100%;
      background: #e7eef2;
      text-align: center;
      margin: 0;
      padding: 2em 1.8em 2.5em;
      border: 1px solid #E4EAEE;
      border-radius: 3px;
      transition: 0.3s ease;
      @media (min-width: 768px) {
        min-width: 250px;
      }

      img {
        margin-bottom: .9375em;
        height: 4.125em;
      }

      h2 {
        text-transform: capitalize;
        font-size: 22px;
        font-weight: 600;
        margin: 0 0 .5em;
        color: $grey;
      }

      p {
        font-weight: 300;
        margin: 0.5rem 0;
        line-height: 1.625;
        letter-spacing: .5px;
        &::before {
          content: '> ';
        }
      }

      .cat-section {
        z-index: 2;
        padding: 0 10px;
      }

      a.cat-clickable {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }

    &:hover, &:active, &:focus {
      .cat__wrapper {
        cursor: pointer;
        background-color: #D3D8DC;
      }
    }
  }
}



/* Card extender */

a.card-extender {
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 0px;
  padding: 10px 0px;
  text-align: center;
  border-top: 2px solid #D3D8DC;
  z-index: 4;
}

a.card-extender:hover {
  opacity: 4;
}

.card-gradient {
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: 0px;
  left: 0px;
  background: linear-gradient(to bottom, rgba(231,238,242,0) 0%,rgba(231,238,242,1) 60%);
  transition: opacity 0.5s 0s ease-in-out;
  pointer-events: none;
  z-index: 3;
}

.category:hover {
  a.card-extender {
    border-top: 2px solid #BDC2C6;
  }

  .card-gradient {
    background: linear-gradient(to bottom, rgba(211,216,220,0) 0%,rgba(211,216,220,1) 60%);
  }
}
