//@import "compass/css3";

/* ==========================================================================
   navbars-01 Specific Styles
   ========================================================================== */

nav.navbars-01 {
  position: relative;
  background-color: #fff;
  ul.topnav {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .nav__elements {
      float: right;
    }
    li a {
      display: block;
      color: #636362;
      text-align: center;
      height: 100%;
      margin: 0 18px;
      padding: 19px 0;
      font-size: 1em;
      box-sizing: border-box;
      &:hover {
        opacity: 0.6;
      }
    }
    li {
      float: left;
      &.nav__brand {
        float: left;
        a {
          padding: 20px 0;
        }
      }
      &.icon {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  nav.navbars-01 {
    ul.topnav {
      li:not(.nav__brand) {
        display: none;
      }
      li.icon {
        float: right;
        display: inline-block;
        a {
          &:hover,
          &:focus,
          &:active {
            opacity: 0.6;
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }
    ul.topnav.responsive {
      position: relative;
      height: auto;
      .nav__elements {
        float: left;
      }
      li.icon {
        position: absolute;
        right: 0;
        top: 0;
      }
      li {
        float: none;
        display: inline;
      }
      li a {
        display: block;
        text-align: left;
        &.nav__item {
          padding: 14px 0;
        }
      }
    }
  }
}
